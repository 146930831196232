/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_language_switcher = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
			
		$(document).on('click','.js-language-switcher',function(){ 
					
			/*
			Get: Language to switch to
			*/
			var _switch_to_language = '';
			if(_language == 'nl') {
				_switch_to_language = 'en';
			} else {
				_switch_to_language = 'nl';
			}

			/*
			Switch
			*/
			specific_language_switcher.switch(
				_switch_to_language
			);

		});

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.switch = function(
		_language
	) {

		/*
		Get: URL
		*/
		var _switch_url_slug = '';

		if(url[3] !== '') { 

			var _page = '';

			if(_language == 'nl') { _page = 'vacatures'; }
			if(_language == 'en') { _page = 'jobs'; }

			_switch_url_slug = _page + '/' + url[3];


		} else {
			
			if(url[2] !== '') { 
				_switch_url_slug = url[2];
			}

		}

		if(_switch_url_slug == '') {
			window.location.href = globalUrl + '/' + _language;
		} else {
			window.location.href = globalUrl + '/' + _language + '/' + _switch_url_slug;
		}
		
	};

}).apply(specific_language_switcher); 