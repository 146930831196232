/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_accordeon = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
			
		/*
		Click
		*/
		$(document).on('click','.js-accordeon-item',function() {

			var _id = $(this).data('id');

			specific_accordeon.open(_id);

		});
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.open = function(_id) {
		
		/*
		Close
		*/
		$('.js-accordeon-content').stop().slideUp();

		if(!$('.js-accordeon-content-' + _id).parent().hasClass('active')) {

			/*
			Open
			*/
			$('.js-accordeon-content-' + _id).stop().slideDown();
			$('.js-accordeon-content-' + _id).parent().addClass('active');

		} else {

			$('.js-accordeon-content-' + _id).parent().removeClass('active');

		}
		

	};

}).apply(specific_accordeon); 